<template>
  <transition
    appear
    mode="out-in"
    name="fade"
  >
    <div
      v-if="discount"
      class="v-discount-timer"
    >
      <div
        class="v-discount-timer-title"
        v-html="translate('discountTimersPage.title')"
      />
      <common-countdown
        :expires="discount.TimeEndSecTS"
        :on-timer-end-event="timerEvent"
      />
    </div>
  </transition>
</template>

<script setup lang="ts">
import type { EmitterEvent } from '~types/common'
import type { CurrentDiscount } from '~types/menuStore'

import type { GUID } from '@arora/common'

const { productId, onTimerEnd } = defineProps<{
  productId: GUID
  onTimerEnd: () => void
}>()

const { translate } = useI18nSanitized()
const menuStore = useMenuStore()

const { eventOn } = useEmitter()

const loaded = computed<boolean>(() => !!menuStore.DiscountTimers?.data)
const discount = ref<CurrentDiscount | undefined>(undefined)

const timerEvent: EmitterEvent = 'v-discount-timer-single'

const discountsData = computed<CurrentDiscount[]>(() => {
  return menuStore.DiscountTimers?.data?.CurrentDiscounts ?? []
})

onMounted(async () => {
  if (loaded.value) {
    await initDiscount()
  } else {
    await menuStore.initDiscountTimers()
  }

  eventOn(timerEvent, () => {
    onTimerEndFunction()
  })
})

async function initDiscount(): Promise<void> {
  discount.value = discountsData.value.find((discount: CurrentDiscount) => {
    return discount.CurrentProductData.ID === productId
  })
}

function onTimerEndFunction(): void {
  discount.value = undefined

  menuStore.loadDiscountTimers().then(() => {
    initDiscount()
    onTimerEnd()
  })
}

watch(
  () => loaded.value,
  async (newState) => {
    if (newState) {
      await initDiscount()
    }
  }
)
</script>

<style lang="scss">
.v-discount-timer {
  float: left;
  width: 100%;

  .v-discount-timer-title {
    font-weight: bold;
    font-size: 22px;
  }
}
</style>
